import { render, staticRenderFns } from "./CourseAreaList.vue?vue&type=template&id=454a3a2f&scoped=true&"
import script from "./CourseAreaList.vue?vue&type=script&lang=js&"
export * from "./CourseAreaList.vue?vue&type=script&lang=js&"
import style0 from "./CourseAreaList.vue?vue&type=style&index=0&id=454a3a2f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "454a3a2f",
  null
  
)

export default component.exports