var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"detailed_course_card_horizontal dark_shadow px-4 pb-1 pt-6",class:{
    'flex-wrap': _vm.$vuetify.breakpoint.smAndDown
},attrs:{"d-flex":""}},[_c('v-flex',{attrs:{"md4":""}},[_c('img',{attrs:{"loading":"lazy","src":_vm.course.imageSrc,"alt":"תמונת הקורס"}})]),_c('v-flex',{staticClass:"pa-5",attrs:{"xs12":"","md4":""}},[_c('strong',[_vm._v(" "+_vm._s(_vm.course.name)+" ")]),_c('br'),_c('small',{domProps:{"innerHTML":_vm._s(_vm.course.description)}})]),_c('v-flex',{staticClass:"ma-md-10 px-10 pb-10 pb-md-0 detailed_course_card_actions",class:{
        'flex-column main_border_right': _vm.$vuetify.breakpoint.mdAndUp
    },attrs:{"xs12":"","md4":"","d-flex":""}},[_c('v-flex',{staticClass:"detailed_course_card_pricing",attrs:{"md6":""}},[_c('strong',[_c('small',[_vm._v(" סה\"כ מחיר ")])]),_c('br'),_c('h2',[_vm._v(" "+_vm._s(_vm.price)+" ")])]),_c('v-flex',{staticClass:"detailed_course_card_expiration mr-10 mr-md-0",attrs:{"md6":""}},[_c('strong',[_c('small',[_vm._v(" תקף עד ")])]),_c('br'),_c('h2',[_vm._v(" "+_vm._s(_vm.expiredAt)+" ")])])],1),_c('div',{staticClass:"buy_button"},[_c('router-link',{attrs:{"to":("/orders?courseId=" + (_vm.course.id))}},[_c('main-button',{attrs:{"shadow":"","styleConfig":{
                    padding: '10px 25px',
                    borderRadius: '30',
                }}},[_c('template',{slot:"content"},[_c('v-flex',{attrs:{"d-flex":"","align-center":"","justify-center":""}},[[_c('strong',{staticClass:"white--text ml-3"},[_vm._v(" לרכישה ")]),_c('arrow')]],2)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }